import { Injectable } from '@angular/core'
import * as Parse from 'parse'
import { Subject } from 'rxjs'
import { EnvironmentService } from './environment.service'

type EventName = 'create' | 'enter' | 'update' | 'delete' | 'leave'
type Events = EventName[]

interface Client {
    on(event: 'open' | 'close' | 'error', cb: () => void): void
    on(event: 'error', cb: (err: Error) => void): void
    close(): void
    open(): void
    subscribe<T extends Parse.Object>(query: Parse.Query<T>, sessionToken: string): PrivateSubscription<T>
}

interface PrivateSubscription<T extends Parse.Object> {
    on: (event: EventName, cb: (object: T) => void) => void
    unsubscribe: () => void
}

export class LiveQuerySubscription<T extends Parse.Object> {
    static count = 0

    private isClosed = false
    readonly id: string
    private publicSubscription: Subject<EventUpdate<T>>
    private privateSubscription: PrivateSubscription<T> | undefined

    constructor(
        private service: ParseLiveQueryService,
        query: Parse.Query<T>,
        options: Events
    ) {
        this.id = '' + LiveQuerySubscription.count
        LiveQuerySubscription.count++
        this.publicSubscription = new Subject<EventUpdate<T>>()

        this.subscribeToQuery(query, options)
    }

    unsubscribe() {
        if (!this.isClosed) {
            if (this.privateSubscription) {
                this.privateSubscription.unsubscribe()
            }
            this.service.removeSubscription(this.id)
            this.isClosed = true
        }
    }

    subscribe(next?: (value: EventUpdate<T>) => void, error?: (error: Error) => void, complete?: () => void) {
        return this.publicSubscription.subscribe(next, error, complete)
    }

    private async subscribeToQuery(query: Parse.Query<T>, options: Events) {
        try {
            await this.service.openConnection()

            const user = Parse.User.current()

            this.privateSubscription = this.service.client.subscribe(query, user ? user.getSessionToken() : '')

            if (!this.service.subscriptions) {
                this.service.subscriptions = []
            }

            this.service.subscriptions.push(this)

            for (const event of options) {
                this.privateSubscription.on(event, (object) => {
                    // console.log('[  ParseLiveQuery.Service ] - subscribeToQuery() - got event - isClosed', this.isClosed)
                    if (!this.isClosed) {
                        this.publicSubscription.next({ object, event, subscription: this })
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export interface EventUpdate<T extends Parse.Object> {
    object: T
    event: EventName
    subscription: LiveQuerySubscription<T>
}
@Injectable()
export class ParseLiveQueryService {
    private connectionOpen: Promise<void> | undefined
    private connectionOpenResolver: (() => void) | undefined
    subscriptions: { id: string }[] | undefined = undefined
    client: Client

    constructor(private environmentService: EnvironmentService) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.client = new Parse.LiveQueryClient({
            serverURL: this.environmentService.wsUrl,
            applicationId: this.environmentService.appId,
        })

        this.client.on('open', () => {
            // console.log('[ ParseLiveQueryService ] - Constructor - onOpen')
            if (this.connectionOpenResolver) {
                this.connectionOpenResolver()
                this.connectionOpenResolver = undefined
            }
        })

        this.client.on('close', () => {
            // console.log('[ ParseLiveQueryService ] - Constructor - onClose')
        })

        this.client.on('error', (err: Error) => {
            console.log('client.on("error")', err)
            this.connectionOpen = undefined
            this.client.close()
        })
    }

    async openConnection() {
        if (this.connectionOpen) {
            return this.connectionOpen
        }

        this.client.open()

        this.connectionOpen = new Promise((resolve, reject) => {
            this.connectionOpenResolver = resolve
        })

        return this.connectionOpen
    }

    public subscribe<T extends Parse.Object>(
        query: Parse.Query<T>,
        options: Events,
        next?: (value: EventUpdate<T>) => void,
        error?: (error: Error) => void,
        complete?: () => void
    ) {
        const l = new LiveQuerySubscription<T>(this, query, options)
        l.subscribe(next, error, complete)
        return l
    }

    async removeSubscription(id: string) {
        let foundId = false
        if (!this.subscriptions) {
            return
        }

        for (let i = this.subscriptions.length - 1; i > -1; i--) {
            const sub = this.subscriptions[i]

            if (sub.id === id) {
                foundId = true
                this.subscriptions.splice(i, 1)
            }
        }

        if (foundId && this.subscriptions.length === 0) {
            this.subscriptions = undefined
            await this.close()
        }
    }

    private async close() {
        this.connectionOpen = undefined
        this.connectionOpenResolver = undefined
        this.client.close()
    }
}
